<template>
  <div class="layout-topbar" v-if="this.$store.state.auth.status.loggedIn">
    <button
      class="p-link layout-menu-button"
      @click="onMenuToggle"
      v-tooltip="'Mostrar/Ocultar menu'"
    >
      <span class="pi pi-bars"></span>
    </button>
    <div class="layout-topbar-icons">
      <i
        class="pi pi-envelope p-mr-4"
        style="font-size: 20px; width: 30px; cursor: pointer"
        v-badge="unreadProposalMsg"
        v-tooltip.left="'Emails Propostas Não Lidos'"
        @click="$router.push('/proposals-unread-messages').catch(() => {})"
      ></i>
      <i
        class="pi pi-inbox mr-4"
        style="font-size: 20px; width: 30px; cursor: pointer"
        v-badge="unreadMsg"
        v-tooltip.left="'Mensagens'"
        @click="$router.push('/messages').catch(() => {})"
      ></i>
      <button class="p-link" @click="logout()" v-tooltip.left="'Logout'">
        <span class="layout-topbar-icon pi pi-sign-out"></span>
      </button>
    </div>
  </div>
</template>

<script>
import msgService from "./services/message.service";
import proposalEmailsService from "./services/proposalEmails.service";
export default {
  data() {
    return {
      unreadMsg: 0,
      unreadProposalMsg: 0,
    };
  },
  created() {
    this.countUnreadMessages();
    if (
      this.can("seeUnreadEmailsProposals") ||
      (this.$store.state.auth.user != undefined &&
        this.$store.state.auth.user.isEsa)
    ) {
      this.countUnreadProposalMessages();
    }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    countUnreadMessages() {
      if (this.$store.state.auth.status.loggedIn) {
        msgService
          .countUnread(this.$store.state.auth.user.id)
          .then((response) => {
            this.unreadMsg = response;
          });
      }
      const timer = setTimeout(() => {
        clearTimeout(timer);
        this.countUnreadMessages();
      }, 5 * 60 * 1000);
    },
    countUnreadProposalMessages() {
      if (this.$store.state.auth.status.loggedIn) {
        proposalEmailsService.countByStatus(1).then((response) => {
          this.unreadProposalMsg = response;
        });
      }
      const timer = setTimeout(() => {
        clearTimeout(timer);
        this.countUnreadMessages();
      }, 5 * 60 * 1000);
    },
  },
};
</script>
<style scope>
.p-tooltip {
  z-index: 1000 !important;
}
.layout-topbar {
  z-index: 1000 !important;
}
</style>

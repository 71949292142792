<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <transition name="layout-sidebar">
      <div
        :class="sidebarClass"
        @click="onSidebarClick"
        v-show="isSidebarVisible()"
        v-if="this.$store.state.auth.status.loggedIn"
      >
        <div class="layout-logo">
          <router-link to="/">
            <img
              src="./assets/icon_w4m.png"
              alt="Logo"
              height="75px"
              width="75px"
            />
          </router-link>
        </div>
        <AppProfile />
        <div class="search-input">
          <div class="p-inputgroup">
            <InputText
              placeholder="Pesquisar"
              v-model="searchValue"
              ref="searchInput"
              id="searchInput"
            />
            <Button
              icon="pi pi-search"
              class="p-button-warning"
              v-on:click="goToSeach(this)"
            />
          </div>
        </div>
        <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
      </div>
    </transition>
    <div
      v-bind:class="[
        { 'no-margin-left': !this.$store.state.auth.status.loggedIn },
        'layout-main',
      ]"
    >
      <router-view />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppProfile from "./AppProfile.vue";
import AppMenu from "./AppMenu.vue";
import AppFooter from "./AppFooter.vue";

export default {
  data() {
    return {
      searchValue: "",
      layoutMode: "static",
      layoutColorMode: "dark",
      staticMenuInactive: !this.$store.state.auth.status.loggedIn,
      overlayMenuActive: false,
      mobileMenuActive: !this.$isMobile(),
      menu: [
        {
          label: "Home",
          icon: "pi pi-fw pi-home",
          command: () => {
            window.location = "/";
          },
        },
        {
          label: "Horas",
          icon: "pi pi-fw pi-clock",
          visible: () => this.currentUser.isEsa || this.can("seeMenuHours"),
          command: () => {
            window.location = "/hours";
          },
        },
        {
          label: "Agenda - Suporte",
          icon: "pi pi-fw pi-calendar",
          visible: () =>
            this.currentUser.isEsa || this.can("seeSupportSchedule"),
          command: () => {
            window.location = "/support";
          },
        },
        {
          label: "Suporte",
          icon: "pi pi-fw pi-sitemap",
          visible: () =>
            this.currentUser.isEsa || this.can("seeSupportCommercial"),
          items: [
            {
              label: "Helpdesk",
              icon: "pi pi-fw pi-wrench",
              visible: () =>
                this.currentUser.isEsa || this.can("seeHelpdeskMenu"),
              items: [
                {
                  label: "Tickets",
                  icon: "pi pi-fw pi-ticket",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeTickets"),
                  to: "/helpdesk-tickets",
                },
                {
                  label: "Emails Ignorados",
                  icon: "pi pi-fw pi-envelope",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeBlockedEmails"),
                  to: "/helpdesk-blocked-emails",
                },
                {
                  label: "Assuntos Ignorados",
                  icon: "pi pi-fw pi-list",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeBlockedSubjects"),
                  to: "/helpdesk-blocked-subjects",
                },
              ],
            },
            {
              label: "Agenda - Suporte",
              icon: "pi pi-fw pi-calendar",
              visible: () =>
                this.currentUser.isEsa || this.can("seeSupportSchedule"),
              command: () => {
                window.location = "/support";
              },
            },
            // {
            //   label: "Propostas",
            //   icon: "pi pi-fw pi-thumbs-up-fill",
            //   visible: () => this.currentUser.isEsa || this.can("seeComercialMenuSupComercial"),
            //   to: "/proposals",
            // },
            {
              label: "Surveys",
              icon: "pi pi-fw pi-bolt",
              visible: () => this.currentUser.isEsa || this.can("seeSurveys"),
              to: "/surveys",
            },
            {
              label: "Instalações",
              icon: "pi pi-fw pi-sliders-h",
              visible: () =>
                this.currentUser.isEsa || this.can("seeInstallations"),
              to: "/installations",
            },
            {
              label: "Assistências",
              icon: "pi pi-fw pi-thumbs-up-fill",
              visible: () => this.currentUser.isEsa || this.can("seeAssists"),
              to: "/assists",
            },
            {
              label: "Mapa das Intervenções",
              icon: "pi pi-map-marker",
              visible: () =>
                this.currentUser.isEsa || this.can("seeInterventionMap"),
              command: () => {
                window.location = "/support-map";
              },
            },
            {
              label: "Inquéritos de Satisfação",
              icon: "pi pi-fw pi-check-circle",
              visible: () =>
                this.currentUser.isEsa || this.can("seeSatisfactionSurveys"),
              items: [
                {
                  label: "Instalações",
                  icon: "pi pi-fw pi-sliders-h",
                  to: "/satisfaction-form-installations",
                  visible: () =>
                    this.currentUser.isEsa ||
                    this.can("seeInstallationSurveys"),
                },
                {
                  label: "Exportar Inq. Instalações",
                  icon: "pi pi-fw pi-external-link",
                  to: "/satisfaction-form-installations-export",
                  visible: () =>
                    this.currentUser.isEsa ||
                    this.can("seeExportInstallationSurveys"),
                },
                {
                  label: "Assistências",
                  icon: "pi pi-fw pi-paypal",
                  to: "/satisfaction-form-support",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeAssistSurveys"),
                },
                {
                  label: "Exportar Inq. Assistências",
                  icon: "pi pi-fw pi-external-link",
                  to: "/satisfaction-form-support-export",
                  visible: () =>
                    this.currentUser.isEsa ||
                    this.can("seeExportAssistSurveys"),
                },
                {
                  label: "Resumo dos Inquéritos de Satisfação",
                  icon: "pi pi-fw pi-table",
                  to: "/satisfaction-form-resume",
                  visible: () =>
                    this.currentUser.isEsa ||
                    this.can("seeExportInstallationSurveys"),
                },
              ],
            },
            {
              label: "Pesquisar Produtos",
              icon: "pi pi-fw pi-search",
              visible: () =>
                this.currentUser.isEsa || this.can("seeSearchProducts"),
              command: () => {
                window.location = "/product-search";
              },
            },
            {
              label: "Horas Técnicos Inativos",
              icon: "pi pi-user-minus",
              visible: () =>
                this.currentUser.isEsa ||
                this.can("seeInactiveTechnicianHours"),
              command: () => {
                window.location = "/hours-inactive-users";
              },
            },
            {
              label: "Reservas",
              icon: "pi pi-calendar",
              visible: () =>
                this.currentUser.isEsa || this.can("seeBookinglistMenu"),
              command: () => {
                window.location = "/support/bookings";
              },
            },
          ],
        },
        {
          label: "Comercial",
          icon: "pi pi-fw pi-briefcase",
          visible: () =>
            this.currentUser.isEsa || this.can("seeCommercialMenu"),
          items: [
            // {
            //   label: "Propostas",
            //   icon: "pi pi-fw pi-thumbs-up-fill",
            //   visible: () => this.currentUser.isEsa || this.can("seeComercialMenuSupComercial"),
            //   to: "/proposals",
            // },
            {
              label: "Surveys",
              icon: "pi pi-fw pi-bolt",
              visible: () => this.currentUser.isEsa || this.can("seeSurveys"),
              to: "/surveys",
            },
            {
              label: "CRM",
              icon: "pi pi-fw pi-wallet",
              visible: () => this.currentUser.isEsa || this.can("seeCRM"),
              to: "/crm",
            },
            {
              label: "CRM V2 (Beta)",
              icon: "pi pi-fw pi-wallet",
              visible: () => this.currentUser.isEsa || this.can("seeCRMV2"),
              to: "/crmV2",
            },
            {
              label: "Classificação de Negócios",
              icon: "pi pi-fw pi-check-square",
              visible: () =>
                this.currentUser.isEsa ||
                this.can("seeBusinessClassification") ||
                this.can("seeBusinessPortfolio"),
              items: [
                {
                  label: "Classificar",
                  icon: "pi pi-fw pi-check-square",
                  visible: () =>
                    this.currentUser.isEsa ||
                    this.can("seeBusinessClassification"),
                  to: "/business-classification",
                },
                {
                  label: "Portfólio",
                  icon: "pi pi-fw pi-shopping-bag",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeBusinessPortfolio"),
                  to: "/business-portfolio",
                },
              ],
            },
          ],
        },
        {
          label: "Recursos Humanos",
          icon: "pi pi-fw pi-users",
          visible: () =>
            this.currentUser.isEsa || this.can("seeHumanResources"),
          items: [
            {
              label: "Férias",
              icon: "pi pi-fw pi-slack",
              visible: () =>
                this.currentUser.isEsa || this.can("seeVacationMenu"),
              items: [
                {
                  label: "As minhas férias",
                  icon: "pi pi-fw pi-slack",
                  to: "/vacations",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeMyvacation"),
                },
                {
                  label: "Férias da equipa",
                  icon: "pi pi-fw pi-users",
                  to: "/vacations-department",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeTeamVacation"),
                },
                {
                  label: "Aprovar",
                  icon: "pi pi-fw pi-check",
                  to: "/vacations-approve",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeApproveVacations"),
                },
              ],
            },
            {
              label: "Despesas",
              icon: "pi pi-fw pi-euro",
              visible: () =>
                this.currentUser.isEsa || this.can("seeExpenditureMenu"),
              items: [
                {
                  label: "Ver",
                  icon: "pi pi-fw pi-eye",
                  to: "/expenditure",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeExpenditures"),
                },
                {
                  label: "Aprovar",
                  icon: "pi pi-fw pi-check",
                  to: "/expenditure-approve",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeExpenditureApprove"),
                },
              ],
            },
            {
              label: "Faltas",
              icon: "pi pi-fw pi-user-minus",
              visible: () => this.currentUser.isEsa || this.can("SeeMissMenu"),
              items: [
                {
                  label: "Ver",
                  icon: "pi pi-fw pi-eye",
                  to: "/miss",
                  visible: () => this.currentUser.isEsa || this.can("seeMiss"),
                },
                {
                  label: "Aprovar",
                  icon: "pi pi-fw pi-check",
                  to: "/miss-approve",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeMissApprove"),
                },
              ],
            },
            {
              label: "Horas Extras",
              icon: "pi pi-fw pi-clock",
              visible: () => this.can("seeExtraHoursMenu"),
              items: [
                {
                  label: "Ver",
                  icon: "pi pi-fw pi-eye",
                  to: "/extra-hours",
                  visible: () => this.can("seeExtraHours"),
                },
                {
                  label: "Aprovar",
                  icon: "pi pi-fw pi-check",
                  to: "/extra-hours-approve",
                  visible: () => this.can("approveExtraHours"),
                },
              ],
            },
            {
              label: "Formações",
              icon: "pi pi-fw pi-book",
              visible: () =>
                this.currentUser.isEsa || this.can("seeTrainingMenu"),
              items: [
                {
                  label: "Necessidades de Formação",
                  icon: "pi pi-fw pi-plus-circle",
                  to: "/training-needs",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeTrainingNeeds"),
                },
                {
                  label: "Ver Formações",
                  icon: "pi pi-fw pi-eye",
                  to: "/training",
                  visible: () =>
                    this.currentUser.isEsa || this.can("SeeTraining"),
                },
                {
                  label: "Minhas Formações",
                  icon: "pi pi-fw pi-bookmark-fill",
                  to: "/my-training",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeMyTraining"),
                },
                {
                  label: "Resumo das Formações",
                  icon: "pi pi-fw pi-list",
                  to: "/training-resume",
                  //visible: () => this.currentUser.isEsa,
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeTrainingResume"),
                },
              ],
            },
            {
              label: "Fichas de Entrevistas",
              icon: "pi pi-fw pi-paperclip",
              // visible: () =>
              //   this.currentUser.isEsa ||
              //   this.currentUser.department == "Administradores",
              command: () => {
                window.location = "/interview-sheet";
              },
              visible: () =>
                this.currentUser.isEsa || this.can("seeInterviewSheet"),
            },
            {
              label: "Fichas dos Colaboradores",
              icon: "pi pi-fw pi-users",
              // visible: () =>
              //   this.currentUser.isEsa ||
              //   this.currentUser.department == "Administradores",
              command: () => {
                window.location = "/users-info";
              },
              visible: () => this.currentUser.isEsa || this.can("seeUsersInfo"),
            },
            {
              label: "Perfil de Competências",
              icon: "pi pi-fw pi-star",
              visible: () =>
                this.currentUser.isEsa || this.can("seeMenuCompetencyProfile"),
              items: [
                {
                  label: "Ver",
                  icon: "pi pi-fw pi-eye",
                  to: "/competency-profile",
                  // visible: () =>
                  //   this.currentUser.isEsa ||
                  //   this.currentUser.department == "Administradores",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeCompetencyProfile"),
                },
                {
                  label: "Resumo",
                  icon: "pi pi-fw pi-list",
                  to: "/competency-profile-resume",
                  // visible: () =>
                  //   this.currentUser.isEsa ||
                  //   this.currentUser.department == "Administradores",
                  visible: () =>
                    this.currentUser.isEsa ||
                    this.can("seeCompetencyProfileResume"),
                },
              ],
            },
            {
              label: "Relatório",
              icon: "pi pi-fw pi-align-justify",
              to: "/human-resources-report",
              visible: () =>
                this.currentUser.isEsa || this.can("seeUsersReport"),
            },
          ],
        },
        {
          label: "W4M",
          icon: "pi pi-fw pi-code",
          visible: () => this.currentUser.isEsa || this.can("seeW4M"),
          items: [
            {
              label: "Horas",
              icon: "pi pi-fw pi-clock",
              visible: () => this.currentUser.isEsa || this.can("seeMenuHours"),
              command: () => {
                window.location = "/hours";
              },
            },
            {
              label: "Manuais",
              icon: "pi pi-fw pi-book",
              command: () => {
                window.location = "/manuals";
              },
              visible: () => this.currentUser.isEsa || this.can("seeManuals"),
            },
            {
              label: "Links Úteis",
              icon: "pi pi-fw pi-link",
              command: () => {
                window.location = "/useful-links-users";
              },
              visible: () =>
                this.currentUser.isEsa || this.can("seeUsefulLinks"),
            },
            {
              label: "Contactos",
              icon: "pi pi-fw pi-phone",
              to: "/contacts",
              visible: () => this.currentUser.isEsa || this.can("seeContacts"),
            },
            {
              label: "ISO",
              icon: "pi pi-fw pi-tag",
              command: () => {
                window.open(
                  "https://owncloud.w4msolutions.com/apps/files/?dir=/ISO%20-%20PARTILHA&fileid=638335",
                  "_blank"
                );
              },
            },
            {
              label: "Divulgações",
              icon: "pi pi-fw pi-discord",
              command: () => {
                window.location = "/disclosure";
              },
              visible: () =>
                this.currentUser.isEsa || this.can("seeDisclosure"),
            },
          ],
        },
        {
          label: "Frota",
          icon: "pi pi-fw pi-car",
          visible: () => this.can("seeFleet"),
          items: [
            {
              label: "Veículos",
              icon: "pi pi-fw pi-car",
              visible: () => this.can("seeFleetVehicles"),
              command: () => {
                window.location = "/vehicles";
              },
            },
          ],
        },
        {
          label: "Administração",
          icon: "pi pi-fw pi-sliders-v",
          visible: () =>
            this.can("seeAdministrationMenu") ||
            this.currentUser.isEsa ||
            this.currentUser.department == "Administradores",
          items: [
            {
              label: "ACLs",
              icon: "pi pi-lock-open",
              visible: () => this.can("seeAclsMenu"),
              items: [
                {
                  label: "Ver Acls",
                  icon: "pi pi-fw pi-eye",
                  visible: () => this.can("seeAcls"),
                  command: () => {
                    window.location = "/acls";
                  },
                },
                {
                  label: "Categorias",
                  icon: "pi pi-fw pi-tag",
                  visible: () => this.can("seeAclsCategories"),
                  command: () => {
                    window.location = "/acls-categories";
                  },
                },
                {
                  label: "Grupos",
                  icon: "pi pi-fw pi-th-large",
                  visible: () => this.can("seeAclsGroups"),
                  command: () => {
                    window.location = "/acls-groups";
                  },
                },
                {
                  label: "Utilizadores",
                  icon: "pi pi-fw pi-users",
                  visible: () => this.can("viewUserGroupAndAcls"),
                  command: () => {
                    window.location = "/acls-users";
                  },
                },
              ],
            },

            {
              label: "Links Utéis",
              icon: "pi pi-fw pi-link",
              to: "/useful-links",
              visible: () =>
                this.currentUser.isEsa || this.can("seeManagementUsefulLinks"),
            },
            {
              label: "CRM",
              icon: "pi pi-fw pi-briefcase",
              visible: () => this.can("seeCrmAdministration"),
              items: [
                {
                  label: "Parceiros",
                  icon: "pi pi-fw pi-building",
                  visible: () => this.can("createCrmClientTypes"),
                  command: () => {
                    window.location = "/crm/admin/client-types";
                  },
                },
                {
                  label: "Categorias",
                  icon: "pi pi-fw pi-tags",
                  visible: () => this.can("createCrmCategories"),
                  command: () => {
                    window.location = "/crm/admin/categories";
                  },
                },
              ],
            },
            {
              label: "Extranet",
              icon: "pi pi-fw pi-globe",
              visible: () =>
                this.currentUser.isEsa || this.can("seeExtranetMenu"),
              items: [
                {
                  label: "Utilizadores",
                  icon: "pi pi-fw pi-users",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeExtranetUsers"),
                  command: () => {
                    window.location = "/extranet-users";
                  },
                },
                {
                  label: "Grupos",
                  icon: "pi pi-fw pi-qrcode",
                  visible: () =>
                    this.currentUser.isEsa || this.can("seeExtranetGroups"),
                  command: () => {
                    window.location = "/extranet-groups";
                  },
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    goToSeach() {
      this.$router.push(`/search/${this.searchValue}`);
    },
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    onLayoutColorChange(layoutColorMode) {
      this.layoutColorMode = layoutColorMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
        else return true;
      } else {
        return true;
      }
    },
  },
  computed: {
    currentUser() {
      if (this.$store.state.auth.status.loggedIn) {
        return this.$store.state.auth.user;
      }
      return null;
    },
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$appState.inputStyle === "filled",
          // 'p-ripple-disabled': this.$primevue.ripple === false
        },
      ];
    },
    sidebarClass() {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": this.layoutColorMode === "dark",
          "layout-sidebar-light": this.layoutColorMode === "light",
        },
      ];
    },
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push("/login").catch(() => {});
    }
    return this.$store.state.auth.user;
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppProfile: AppProfile,
    AppMenu: AppMenu,
    AppFooter: AppFooter,
  },
};
</script>
<style>
.no-margin-left {
  margin-left: 0px !important;
}

.search-input {
  width: 90%;
  margin: 10px auto;
}
</style>
